import { Navigate } from 'react-router-dom';
import usePrivateRoute from '../hooks/usePrivateRoute';
import Loader from '../ui-component/Loader';

const withPrivate = (WrappedComponent) => (props) => {
  const { isLoading, redirect } = usePrivateRoute();

  if (isLoading) {
    return <Loader />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return <WrappedComponent {...props} />;
};

export default withPrivate;
