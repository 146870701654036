import { useLocation } from 'react-router-dom';
import Utils from '../utils/utils';
import Paths from '../routes/paths';
import { useUser } from '../store/selectors';

const usePrivateRoute = () => {
  const location = useLocation();
  const { isLoading, data: user } = useUser();

  let redirect = '';
  if (!user) {
    redirect = Utils.prepareReturnURL(Paths.login, location);
  }

  return { isLoading, redirect };
};

export default usePrivateRoute;
