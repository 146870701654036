import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'Layout/MinimalLayout';
import Paths from './paths';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/Login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: Paths.home,
  element: <MinimalLayout />,
  children: [
    {
      path: Paths.login,
      element: <AuthLogin />
    }
  ]
};

export default AuthenticationRoutes;
