import { PARAM_RETURN_URL } from '../constants';
import { format, utcToZonedTime } from 'date-fns-tz';
import formatDistance from 'date-fns/formatDistance';

class Utils {
  static prepareReturnURL = (path, location) =>
    `${path}?${PARAM_RETURN_URL}=${encodeURIComponent(
      typeof location === 'string' ? location : `${location.pathname}${location.search}${location.hash}`
    )}`;

  static getCountryOptions(countries) {
    const countriesOption = [];
    countries?.forEach((country) => {
      countriesOption.push({
        label: country.name,
        value: country.id
      });
    });
    return countriesOption;
  }

  static getCategoryOptions(categories) {
    let categoryChildren = [];

    categories?.forEach((category) => {
      categoryChildren = categoryChildren.concat(category.children);
    });
    const categoriesOptions = [];
    categoryChildren.forEach((category) => {
      const currentOptions = category.children.map((cat) => ({
        value: cat.id,
        label: cat.name
      }));
      if (currentOptions.length === 0) {
        currentOptions.push({ value: category.id, label: category.name });
      }
      categoriesOptions.push({
        label: category.name,
        options: currentOptions
      });
    });

    return categoriesOptions;
  }

  static getUserOptions(users) {
    const usersOptions = [];
    users?.forEach((user) => {
      usersOptions.push({
        label: `${user?.first_name} ${user?.last_name}${user?.email ? ` (${user?.email})` : ''}`,
        value: user.id
      });
    });
    return usersOptions;
  }

  static formatDate = (date) => Utils.formatDateFromRequest(date, undefined, false, 'E, dd MMMM h:mm a');

  static formatDateFromRequest(dateFromRequest, timeZone, distanceDate, dateFormat = 'MM-dd-yyyy HH:mm:ss') {
    try {
      if (!dateFromRequest) {
        return '';
      }
      let dateToConvert = '';

      const newTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (dateFromRequest.indexOf('Z') === -1) {
        dateToConvert = dateFromRequest.replace(' ', 'T').concat('.', '000Z');
      } else {
        dateToConvert = dateFromRequest;
      }

      const dateZoned = utcToZonedTime(new Date(dateToConvert), newTimeZone);

      const now = utcToZonedTime(new Date(), newTimeZone);

      let dateFormatted = '';
      if (!distanceDate) {
        dateFormatted = format(dateZoned, dateFormat, {
          timeZone: newTimeZone
        });
      } else {
        dateFormatted = formatDistance(dateZoned, now, { addSuffix: true });
      }
      return dateFormatted;
    } catch (exception) {
      return '';
    }
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export default Utils;
