import { Navigate } from 'react-router-dom';
import useAuthRoute from '../hooks/useAuthRoute';
import Loader from '../ui-component/Loader';

const withAuth = (WrappedComponent) => (props) => {
  const { isLoading, redirect } = useAuthRoute();

  if (isLoading) {
    return <Loader />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return <WrappedComponent {...props} />;
};

export default withAuth;
