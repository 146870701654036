import { PathsAPI } from '../routes/paths';
import Fetcher from './Fetcher';

// TODO: we'll split everything in files later
class ClientService {
  static async postLogin(data) {
    const { data: response } = await Fetcher.post(PathsAPI.login, data);
    return response;
  }

  static async postLogout(data = {}) {
    const { data: response } = await Fetcher.post(PathsAPI.logout, data);
    return response;
  }

  static async getUserInfo() {
    const { data: response } = await Fetcher.get(PathsAPI.userInfo);
    return response;
  }

  static async getUsers(page, country, categories, search) {
    let url = PathsAPI.users.replace('$1', page);
    if (country) {
      url = url.replace('$2', `&countries=${country}`);
    } else {
      url = url.replace('$2', '');
    }
    if (categories) {
      const selectedCategories = categories.map((cat) => cat.value);
      url = url.replace('$3', `&categories=${selectedCategories}`);
    } else {
      url = url.replace('$3', '');
    }
    if (search) {
      url = url.replace('$4', `&search=${search}`);
    } else {
      url = url.replace('$4', '');
    }
    const { data: response } = await Fetcher.get(url);
    return response;
  }

  static async getUsersForOption(page, search) {
    let url = PathsAPI.usersOptions.replace('$1', page);
    if (search) {
      url = url.replace('$2', `&search=${search}`);
    } else {
      url = url.replace('$2', '');
    }
    const { data: response } = await Fetcher.get(url);
    return response;
  }

  static async getCompanies(page, search) {
    let url = PathsAPI.companies.replace('$1', page);
    if (search) {
      url = url.replace('$2', `&search=${search}`);
    } else {
      url = url.replace('$2', '');
    }
    const { data: response } = await Fetcher.get(url);
    return response;
  }

  static async getAllCategories() {
    const { data: response } = await Fetcher.get(PathsAPI.categories);
    return response.data;
  }

  static async getAllCountries() {
    const { data: response } = await Fetcher.get(PathsAPI.countries);
    return response.data;
  }

  static async getUsersStatistics() {
    const { data: response } = await Fetcher.get(PathsAPI.usersStatistic);
    return response;
  }

  static async getSessionsStatistics() {
    const { data: response } = await Fetcher.get(PathsAPI.sessionsStatistic);
    return response;
  }

  static async getTasksStatistics() {
    const { data: response } = await Fetcher.get(PathsAPI.tasksStatistic);
    return response;
  }

  static async getLastSession() {
    const { data: response } = await Fetcher.get(PathsAPI.lastSession);
    return response.data;
  }

  static async postAddCompany(data) {
    const { data: response } = await Fetcher.post(PathsAPI.addCompany, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }

  static async postEditCompany(id, data) {
    const { data: response } = await Fetcher.post(`${PathsAPI.addCompany}/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }

  static async postUpdateTaskImage(id, data) {
    const { data: response } = await Fetcher.post(`${PathsAPI.addCompany}/${id}/task/avatar/add`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  }
}

export default ClientService;
