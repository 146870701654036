import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // TODO: FIX multiple requests when multiple components use same query
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // if SSR failed, client will try again
      retryOnMount: true,
      // TODO: fix retryDelay - 1000ms is really bad!
      retryDelay: 10000,
      // example - cancel on 404 and 403
      retry: (failureCount, error) => ![403, 404].includes(error.response?.status)
    }
  }
});

export default queryClient;
