import axios from 'axios';
import { QUERY_USER_INFO, STORAGE_TOKEN } from '../constants';
import Utils from '../utils/utils';
import history from '../utils/history';
import Client from './methods';
import Paths from '../routes/paths';
import queryClient from './query';

/**
 * We'll keep it simple here, only add API related stuff here.
 * React Query does everything else for us...
 */
const Fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

// Authentication

Fetcher.interceptors.request.use((config) => {
  const request = config;
  const user = Client.getToken();

  if (user) {
    request.headers.common.Authorization = `Bearer ${user.token}`;
  }

  return request;
}, Promise.reject);

/** [Handle unauthorized requests - token currently doesn't expire]
 * This is strictly on the client only, for SSR it should go in the server part.
 */
Fetcher.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && history && history.location.pathname !== Paths.login) {
      localStorage.removeItem(STORAGE_TOKEN);
      queryClient.setQueryData(QUERY_USER_INFO, null);
      history.push(Utils.prepareReturnURL(Paths.login, history.location));
    }
    return Promise.reject(error);
  }
);

Fetcher.interceptors.response.use(
  (response) => {
    if (typeof response.data.message === 'string') {
      const text = response.data.message;

      response.data.message = {
        text
      };
    }

    // console.log('RESPONSE', response?.data);
    return response;
  },
  (error) => {
    console.log('ERR ERR', { error });

    const customError = { ...error };

    if (customError.response?.data.errors && Object.keys(customError.response?.data.errors).length > 0) {
      console.log('ERR ERR FROM HERE 2', customError.response?.data.errors);
      const { errors } = customError.response.data;
      customError.data = {
        errors
      };
    } else if (typeof customError.response?.data.message === 'string') {
      console.log('ERR ERR FROM HERE');

      const text = customError.response?.data.message;
      customError.data = {
        errors: { message: text }
      };
    }

    return Promise.reject(customError);
  }
);

export default Fetcher;
