import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import serialize from 'serialize-javascript';
import { QUERY_USER_INFO, STORAGE_TOKEN } from '../constants';
import Paths from '../routes/paths';
import { useUser } from '../store/selectors';

const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { refetch } = useUser();

  const storeToken = (token) => {
    console.log('token', token);
    console.log(serialize({ token }));
    localStorage.setItem(STORAGE_TOKEN, serialize({ token }));
  };

  const handleUserLogin = async (token) => {
    storeToken(token);
    await refetch();
  };

  const handleUserLogout = async (skipRedirect = false) => {
    localStorage.removeItem(STORAGE_TOKEN);
    queryClient.setQueryData(QUERY_USER_INFO, null);

    if (!skipRedirect) {
      navigate(Paths.login);
    }
  };

  return { handleUserLogin, handleUserLogout };
};

export default useAuth;
