import { useSearchParam } from 'react-use';
import { PARAM_RETURN_URL } from '../constants';
import Paths from '../routes/paths';
import { useUser } from '../store/selectors';

const useAuthRoute = () => {
  const { isLoading, data: user } = useUser();
  const returnURL = useSearchParam(PARAM_RETURN_URL);

  // No useEffect needed - we can't wait for another state here!
  let redirect = '';
  if (user) {
    redirect = returnURL ?? Paths.home;
  }

  return { isLoading, redirect };
};

export default useAuthRoute;
