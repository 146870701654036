export const STORAGE_TOKEN = 'token';
export const PARAM_RETURN_URL = 'returnURL';

// queries
export const QUERY_USER_INFO = 'user_info';
export const QUERY_USERS = 'users';
export const QUERY_CATEGORIES = 'categories';
export const QUERY_COMPANIES = 'companies';
export const QUERY_COUNTRIES = 'counties';
export const QUERY_USERS_STATISTIC = 'user_statistic';
export const QUERY_SESSIONS_STATISTIC = 'sessions_statistic';
export const QUERY_TASKS_STATISTIC = 'tasks_statistic';
export const QUERY_LAST_SESSION = 'last_session';
