import { createBrowserHistory } from 'history';

/**
 * @see https://stackoverflow.com/a/58506212
 */
const history = createBrowserHistory({
  basename: window.location.pathname
});

export default history;
