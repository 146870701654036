// assets
import { IconBrandChrome, IconUsers, IconHelp, IconBuildingSkyscraper, IconLayoutList } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconUsers, IconHelp, IconBuildingSkyscraper, IconLayoutList };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'users-page',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'companies-page',
      title: 'Companies',
      type: 'item',
      url: '/companies',
      icon: icons.IconBuildingSkyscraper,
      breadcrumbs: false
    },
    {
      id: 'tasks-page',
      title: 'Tasks',
      type: 'item',
      url: '/tasks',
      icon: icons.IconLayoutList,
      breadcrumbs: false
    }
  ]
};

export default other;
