import { useQuery } from 'react-query';
import {
  QUERY_CATEGORIES,
  QUERY_COMPANIES,
  QUERY_COUNTRIES,
  QUERY_LAST_SESSION,
  QUERY_SESSIONS_STATISTIC,
  QUERY_TASKS_STATISTIC,
  QUERY_USER_INFO,
  QUERY_USERS,
  QUERY_USERS_STATISTIC
} from '../constants';
import ClientService from '../services/ClientService';

const useUser = () =>
  useQuery(QUERY_USER_INFO, () => ClientService.getUserInfo(), {
    staleTime: 10000,
    retry: false
  });

const useUsers = (page, country, categories, search) =>
  useQuery([QUERY_USERS, page], () => ClientService.getUsers(page, country, categories, search), {
    keepPreviousData: true
  });

const useCompanies = (page, search) =>
  useQuery([QUERY_COMPANIES, page], () => ClientService.getCompanies(page, search), {
    keepPreviousData: true
  });

const useCategories = () =>
  useQuery(QUERY_CATEGORIES, () => ClientService.getAllCategories(), {
    staleTime: 10000
  });

const useCountries = () =>
  useQuery(QUERY_COUNTRIES, () => ClientService.getAllCountries(), {
    staleTime: 10000
  });

const useUsersStatistic = () =>
  useQuery(QUERY_USERS_STATISTIC, () => ClientService.getUsersStatistics(), {
    staleTime: 10000
  });

const useSessionsStatistic = () =>
  useQuery(QUERY_SESSIONS_STATISTIC, () => ClientService.getSessionsStatistics(), {
    staleTime: 10000
  });

const useTasksStatistic = () =>
  useQuery(QUERY_TASKS_STATISTIC, () => ClientService.getTasksStatistics(), {
    staleTime: 10000
  });

const useLastSession = () =>
  useQuery(QUERY_LAST_SESSION, () => ClientService.getLastSession(), {
    staleTime: 10000
  });

export {
  useUser,
  useUsers,
  useCompanies,
  useCountries,
  useCategories,
  useUsersStatistic,
  useTasksStatistic,
  useSessionsStatistic,
  useLastSession
};
