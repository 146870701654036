import { STORAGE_TOKEN } from '../constants';

class Client {
  static getToken = () => {
    let token;
    const storedToken = JSON.parse(localStorage.getItem(STORAGE_TOKEN) || '{}');
    if (storedToken?.token) {
      // that's enough for the token to operate on the client
      token = storedToken;
    }
    return token;
  };
}

export default Client;
