import { Outlet } from 'react-router-dom';

// project imports
import withAuth from '../../hocs/withAuth';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <Outlet />
    {/* <Customization /> */}
  </>
);

export default withAuth(MinimalLayout);
