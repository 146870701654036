import { lazy } from 'react';

// project imports
import MainLayout from 'Layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Paths from './paths';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// user page routing
const UsersPage = Loadable(lazy(() => import('views/users')));

// companies page routing
const CompaniesPage = Loadable(lazy(() => import('views/companies')));

const TasksPage = Loadable(lazy(() => import('views/tasks')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: Paths.home,
  element: <MainLayout />,
  children: [
    {
      path: Paths.home,
      element: <DashboardDefault />
    },
    {
      path: Paths.users,
      element: <UsersPage />
    },
    {
      path: Paths.companies,
      element: <CompaniesPage />
    },
    {
      path: Paths.tasks,
      element: <TasksPage />
    }
  ]
};

export default MainRoutes;
