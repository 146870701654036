import { useMutation } from 'react-query';
import ClientService from '../services/ClientService';
import useAuth from './useAuth';

const useMutationLogout = () => {
  const { handleUserLogout } = useAuth();

  const logout = useMutation(ClientService.postLogout, {
    onError: console.error,
    onSuccess: () => {
      handleUserLogout();
    }
  });

  return { mutateLogout: () => logout.mutate({}) };
};

export default useMutationLogout;
