const Paths = {
  home: '/',
  login: '/login',
  users: '/users',
  companies: '/companies',
  tasks: '/tasks'
};

export const PathsAPI = {
  login: '/login',
  logout: '/logout',
  userInfo: '/user/info',
  users: '/super-admin/users?page=$1&limit=10$2$3$4',
  usersOptions: '/super-admin/users?page=$1&limit=50$2',
  companies: '/super-admin/companies?page=$1&limit=10$2',
  categories: '/categories',
  countries: '/countries',
  usersStatistic: '/super-admin/user-statistics',
  lastSession: '/super-admin/last-given-session',
  sessionsStatistic: '/super-admin/session-statistics',
  tasksStatistic: '/super-admin/task-statistics',
  addCompany: '/super-admin/companies'
};

export default Paths;
